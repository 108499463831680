<template>
  <CytomineModalCard :title="$t('annotations-copy')" @close="$emit('close')">
    <BLoading :is-full-page="false" :active="cloneRequest.pending" />

    <template v-if="!projectData.selectedProject">
      <h3 class="mb-3 size-20">
        {{ $t('projects') }}
      </h3>
      <IdxInput
        v-model="projectData.search"
        :placeholder="$t('search')"
        :label="$t('search')"
        type="search"
        icon="search"
        name="name[ilike]"
      />

      <IdxTable
        :key="'projects-table'"
        :loading="fetchProjectsRequest.pending"
        :current-page.sync="projectData.page"
        :per-page.sync="projectData.perPage"
        :sort-by.sync="projectData.sortBy"
        :sort-direction="projectData.sortDirection"
        :total="projectData.total"
        :data="projectData.projects"
      >
        <template #default>
          <BTableColumn v-slot="props" :label="$t('name')" field="name">
            {{ props.row.name }}
          </BTableColumn>

          <BTableColumn v-slot="props" label=" " centered>
            <IdxBtn
              small
              color="blue"
              @click="projectData.selectedProject = props.row"
            >
              {{ $t('select') }}
            </IdxBtn>
          </BTableColumn>
        </template>

        <template #empty>
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('no-image') }}</p>
          </div>
        </template>
      </IdxTable>
    </template>

    <template v-else>
      <div class="flex mb-3">
        <h3 class="size-20">
          {{ projectData.selectedProject.name }}: {{ $t('images') }}
        </h3>
        <IdxBtn plain class="ml-2" @click="projectData.selectedProject = null">
          <BIcon icon="times" class="color-blue" />
          <span class="visually-hidden">{{ $t('remove') }}</span>
        </IdxBtn>
      </div>
      <IdxInput
        v-model="imageData.search"
        :placeholder="$t('search')"
        :label="$t('search')"
        type="search"
        icon="search"
        name="name[ilike]"
      />

      <IdxTable
        :key="'image-table'"
        :loading="fetchImagesRequest.pending"
        :current-page.sync="imageData.page"
        :per-page.sync="imageData.perPage"
        :sort-by.sync="imageData.sortBy"
        :order-direction.sync="imageData.sortDirection"
        :total="imageData.total"
        :data="imageData.images"
      >
        <template #default>
          <BTableColumn v-slot="props" :label="$t('overview')">
            <img
              :src="props.row.thumb.replace(512, 256)"
              :alt="props.row.originalFilename"
              loading="lazy"
            />
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('name')"
            field="originalFilename"
          >
            {{ props.row.instanceFilename }}
          </BTableColumn>

          <BTableColumn v-slot="props" label=" " centered>
            <template v-if="props.row.id === image.id">
              -
            </template>
            <IdxBtn
              v-else
              small
              color="blue"
              @click="cloneAnnotationsTo(props.row)"
            >
              {{ $t('select') }}
            </IdxBtn>
          </BTableColumn>
        </template>

        <template #empty>
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('no-image') }}</p>
          </div>
        </template>
      </IdxTable>
    </template>
  </CytomineModalCard>
</template>

<script>
import { defineComponent, reactive, watch } from '@vue/composition-api';
import debounce from 'lodash/debounce.js';
import { CytomineModalCard, IdxTable } from '@/components/utils/index.js';
import { useAsync } from '@/composables/index.js';
import noteApi, { getProjects, getProjectImages } from '@/services/noteApi.js';

const defaultData = {
  search: '',
  page: 1,
  perPage: 10,
  total: 0,
  sortBy: 'created',
  /** @type {'asc' | 'desc'} */
  sortDirection: 'desc',
};

export default defineComponent({
  name: 'ListImagesCloneAnnotationsModal',
  components: {
    IdxTable,
    CytomineModalCard,
  },
  props: {
    /** @type {import('vue').PropOptions<CytoImageInstance>} */
    image: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const projectData = reactive({
      ...defaultData,
      projects: [],
      /** @type {CytoProject} */
      selectedProject: null,
    });
    async function localFetchProjects() {
      const response = await getProjects(projectData);
      projectData.total = response.size;
      projectData.projects = response.collection;
    }
    const fetchProjectsRequest = useAsync(localFetchProjects);
    watch(
      () => projectData.search,
      debounce(() => fetchProjectsRequest.watch(localFetchProjects), 500)
    );
    watch(
      () => [
        projectData.page,
        projectData.perPage,
        projectData.sortBy,
        projectData.sortDirection,
      ],
      () => fetchProjectsRequest.watch(localFetchProjects)
    );

    const imageData = reactive({
      ...defaultData,
      images: [],
    });
    async function fetchImages() {
      const response = await getProjectImages({
        projectId: projectData.selectedProject.id,
        ...imageData,
      });
      imageData.total = response.size;
      imageData.images = response.collection;
    }
    const fetchImagesRequest = useAsync();
    watch(
      () => projectData.selectedProject,
      (project) => {
        if (project) {
          fetchImagesRequest.watch(fetchImages);
        }
      }
    );
    watch(
      () => imageData.search,
      debounce(() => fetchImagesRequest.watch(fetchImages), 500)
    );
    watch(
      () => [
        imageData.page,
        imageData.perPage,
        imageData.sortBy,
        imageData.sortDirection,
      ],
      () => fetchImagesRequest.watch(fetchImages)
    );

    const cloneRequest = useAsync();

    return {
      projectData,
      fetchProjectsRequest,

      imageData,
      fetchImagesRequest,

      cloneRequest,
      cloneAnnotationsTo(listedImage) {
        cloneRequest.watch(async () => {
          try {
            await noteApi.post(`/napi/annotation/clone`, {
              json: {
                fromImage: props.image.id,
                toImage: listedImage.id,
                toProject: projectData.selectedProject.id,
                fromProject: props.image.project,
              },
            });
            emit('success');
          } catch (error) {
            console.log(error);
            emit('error', error);
          }
        });
      },
    };
  },
});
</script>
